import React from 'react'
import SvgIcon from "../SvgCurve"

export default function StepCart(props) {
    const { path } = props

    const setClassName = (pathItem) => {
        return path === pathItem && "selected"
    }

    return (
        <div className="StepCart">
            <div  className={`StepCart-item ${setClassName("/cesta/cart/")}`}><SvgIcon className="StepCart-icon" icon="cart" /></div>
            <div className={`StepCart-item ${setClassName("/cesta/login/")} ${setClassName("/cesta/register/")}`}><SvgIcon className="StepCart-icon" icon="userCart"/></div>
            <div className={`StepCart-item ${setClassName("/cesta/buy/")}`}><SvgIcon className="StepCart-icon" icon="box" /></div>
            <div className={`StepCart-item ${setClassName("/cesta/success/")}`}><SvgIcon className="StepCart-icon" icon="check"/></div>
        </div>
    )
}
