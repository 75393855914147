import image1 from "../images/ShoppingCart/shoppincart-1.jpg"
import image2 from "../images/ShoppingCart/shoppincart-2.jpg"

export const shoppingList = [
    {
        image: image1,
        name:"ROSARIO CON MEDALLA DE LA SAGRADA FAMILIA, EN PLATA",
        price:"24.50"
    },
    {
        image: image2,
        name:"LA BASILICA DE LA SAGRADA FAMILIA (CAST)",
        price:"20.50"
    },
]