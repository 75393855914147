import React from 'react'
import Proptypes from 'prop-types'


export default function ShoppingCartResume(props) {
    const { quantity, subtotal, shippingCost, total, redirectPage } = props
    
    return (
        <div className="ShoppingCartResume">
            <span className="ShoppingCartResume-title">
                Resumen
            </span>
            <div className="ShoppingCartResume-row">
                <span className="ShoppingCartResume-quantity">{`Cesta(${quantity})`}</span>
                <span className="ShoppingCartResume-price">{`${subtotal}€`}</span>
            </div>
            <div className="ShoppingCartResume-row iva">
                <span className="ShoppingCartResume-quantity">Envio</span>
                <span className="ShoppingCartResume-price">{`${shippingCost}€`}</span>
            </div>
            <div className="ShoppingCartResume-row total">
                <span className="ShoppingCartResume-quantity">Total <span className="ShoppingCartResume-iva">(IVA incluido)*</span></span>
                <span className="ShoppingCartResume-price">{`${total}€`}</span>
            </div>
            <button onClick={redirectPage} className="ShoppingCartResume-btn">Tramitar Pedido</button>
        </div>
    )
}


ShoppingCartResume.defaultProps = {
    quantity: "",
    subtotal: "",
    shippingCost: "",
    total: "",
}

ShoppingCartResume.propTypes = {
    quantity: Proptypes.string,
    subtotal: Proptypes.string,
    shippingCost: Proptypes.string,
    total: Proptypes.string,
}