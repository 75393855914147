import React from 'react'
import Layout from "../../components/Layout"
import ShoppingCartList from "../../components/ShoppingCart/ShoppingCartList"
import ShoppingCartResume from "../../components/ShoppingCart/ShoppingCartResume"
import StepCart from "../../components/StepCart"
import { navigate } from "gatsby"


export default function ShoppingCart(props) {
    const { path } = props

    const redirectPage = () => {
        return  navigate("/cesta/login/")
    }

    return (
        <Layout>
            <div className="ShoppingCart">
                <div className="ShoppingCart-container">
                    <div className="row">
                        <div className="col-12">
                            <StepCart path={path} />
                        </div>
                        <div className="col-12 col-md-7">
                            <ShoppingCartList />
                        </div>
                        <div className="col-12 col-md-5">
                            <ShoppingCartResume redirectPage={redirectPage} quantity="3" subtotal="175" shippingCost="0" total="175" />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
