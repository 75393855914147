import React, { useState } from "react";

export default function ShoppingCartCounter() {
	const [counter, setCounter] = useState(0);

	const handleChangeNumber = (type) => {
		let nextValue = counter;
		switch (type) {
			case "prev":
				nextValue--;
				if (nextValue === -1) return;
				setCounter(nextValue);
				break;
			case "next":
				nextValue++;
				setCounter(nextValue);
				break;
			default:
				break;
		}
	};
	return (
		<div className="ShoppingCartCounter">
			<button
				className="ShoppingCartCounter-counter minus"
				onClick={() => handleChangeNumber("prev")}
			>
				<span>&#8722;</span>
			</button>
			<div className="ShoppingCartCounter-quantity">
				<span>{counter}</span>
			</div>
			<button
				className="ShoppingCartCounter-counter plus"
				onClick={() => handleChangeNumber("next")}
			>
				<span>&#43;</span>
			</button>
		</div>
	);
}
