import React from 'react'
import ShoppingCartItem from "./ShoppingCartItem"
import SvgIcon from "../SvgCurve"
import { shoppingList } from '../../data/shooping-list'

export default function ShoppingCartList() {

    const renderShoppingCartList = () => {
        return shoppingList&&shoppingList.map((item) => {
            return(
                <ShoppingCartItem 
                    image={item.image} 
                    name={item.name} 
                    price={item.price} 
                />
            )
        })
    }

    return (
        <div className="ShoppingCartList">
            <span className="ShoppingCartList-title">
                <SvgIcon className="ShoppingCartList-icon" icon="cart" />
                Tu cesta
            </span>
            <div className="ShoppingCartList-list">
                {renderShoppingCartList()}
            </div>
            <span className="ShoppingCartList-text">* Recuerda que los precios y los gastos de envío se confirmarán cuando hayas llegado a finalizar la compra</span>
        </div>
    )
}
