import React from 'react'
import Proptypes from 'prop-types'
import Counter from "./ShoppingCartCounter"
import SvgIcon from "../SvgCurve"

export default function ShoppingCartItem(props) {
    const { image, name, price } = props;

    return (
        <div className="ShoppingCartItem">
            <div className="ShoppingCartItem-image" style={{ backgroundImage: `url(${image})` }} />
            <div className="ShoppingCartItem-wrapper">
                <span className="ShoppingCartItem-name">{name}</span>
                <Counter />
            </div>
            <div className="ShoppingCartItem-wrapper action">
                <button className="ShoppingCartItem-remove">
                    <SvgIcon className="ShoppingCartItem-removeIcon" icon="trashIcon" />
                </button>
                <span className="ShoppingCartItem-price">{`${price}€`}</span>
            </div>
        </div>
    )
}

ShoppingCartItem.defaultProps = {
    image:"",
    name:"",
    pice:"",
}

ShoppingCartItem.propTypes = {
    image: Proptypes.string,
    name: Proptypes.string,
    pice: Proptypes.string
}